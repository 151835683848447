import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';

import { COLORS } from '../../styles';
import { Flex } from '../flex';
import { Icon } from '../icon';
import type { IconType } from '../icon/icons';

interface OverlayContentProps {
  title: string;
  message: string;
  rootId?: string;
  icon?: IconType;
  actionSlotElement?: ReactNode;
}

export const OverlayContent = ({
  title,
  message,
  icon,
  rootId,
  actionSlotElement,
}: OverlayContentProps) => (
  <Box
    data-test-id={rootId}
    sx={{
      width: { sm: '536px' },
      border: `2px dashed ${COLORS.SILVER}`,
      borderRadius: '32px',
      p: 4,
    }}
  >
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        py: 2,
      }}
    >
      <Flex sx={{ alignItems: 'center', gap: 1, mb: 4 }}>
        {icon && <Icon icon={icon} />}
        <Typography data-test-class="overlay-title" variant="h3">
          {title}
        </Typography>
      </Flex>
      <Typography data-test-class="overlay-message">{message}</Typography>
      {actionSlotElement && (
        <Box pt={4} data-test-class="overlay-action-container">
          {actionSlotElement}
        </Box>
      )}
    </Flex>
  </Box>
);
