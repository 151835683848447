export enum FeatureToggle {
  ALERTS_VISIT_SEVERITY_FEATURE = 'pwrfleet.alerts.visit-severity.feature',
  HIDE_CHART_BREAKDOWN_TOGGLE = 'pwrfleet.site-dashboard.hide-show-breakdown-toggle', // Installers don't need to see the breakdown toggle because it's confusing. See https://neurio.atlassian.net/browse/FM-4061
  SHOW_EVENT_LOG_ON_SINGLE_DEVICE_PAGE = 'pwrfleet.device.charts.enable-event-log-in-single-device-chart',
  REMOTE_SETTINGS_ENABLE_ACTIVE_SYSMODES = 'pwrfleet.remote-settings.active-system-modes',
  REMOTE_SETTINGS_ENABLE_BATTERY_SETTINGS = 'pwrfleet.remote-settings.enable-battery-settings',
  REMOTE_SETTINGS_ENABLE_INVERTER_SETTINGS = 'pwrfleet.remote-settings.enable-inverter-settings',
  SITE_LIST_ENABLE_V2 = 'pwrfleet.sitelist.enable-V2.FE',
  RETIRE_SYSTEM_ENABLE = 'pwrfleet.retire-system.enable',
  R2_REMOTE_SETTINGS_ENABLE = 'pwrfleet.R2-remote-settings.enable',
}

export type FeatureToggleList = FeatureToggle[keyof FeatureToggle][];
