import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import invariant from 'invariant';
import { uniq } from 'lodash';
import { DateTime, Interval } from 'luxon';
import qs from 'qs';

import type { Account } from '../types/Account';
import type {
  AlertDetailsRequest,
  AlertDetailsResponse,
  AlertInstanceHistoryRequest,
  AlertInstanceHistoryResponse,
  CompanyAlertsRequest,
  CompanyAlertsResponse,
  SiteAlertsRequest,
  SiteAlertsResponse,
} from '../types/Alert';
import type { AuthRefreshTokenResponse } from '../types/Auth';
import type { CompaniesMergeRequest, CompaniesMergeResponse } from '../types/CompaniesMerge';
import type {
  Company,
  CompanyId,
  UpdateCompanyBody,
  UpdateCompanyResponse,
} from '../types/Company';
import type {
  ActivateCompanyAssociationResponse,
  AssociateCompaniesRequest,
  AssociateCompaniesResponse,
  CompanyAssociationsResponse,
  RemoveCompanyAssociationResponse,
} from '../types/CompanyAssociation';
import type {
  CompanyUsersResponse,
  PendingCompanyUsersResponse,
  RemoveCompanyUserRequest,
  RemoveCompanyUserResponse,
  UpdateCompanyUserRoleRequest,
  UpdateCompanyUserRoleResponse,
} from '../types/CompanyUser';
import type { DeviceEventLogRequest, DeviceEventLogResponse } from '../types/DeviceEvent';
import type { Fleet } from '../types/Fleet';
import type { LoadManagerPairRequest, LoadManagerPairResponse } from '../types/LoadManager';
import type { SignUpRequest, SignUpResponse } from '../types/SignUp';
import type {
  MapSite,
  SiteListExportParams,
  SiteListExportResponse,
  SiteQueryParams,
  SitesRequest,
  SitesResponse,
} from '../types/Site';
import type { SiteAssociationsResponse } from '../types/SiteAssociation';
import type { SiteAddressRequest, SiteAddressResponse } from '../types/SiteDetails';
import type {
  ArrayLayoutTelemetry,
  SiteLayoutResponse,
  SiteLayoutSaveRequest,
  SiteLayoutSaveResponse,
  SiteLayoutTelemetryRequest,
  SiteLayoutTelemetryResponse,
  SiteLayoutTelemetrySuccessResponse,
} from '../types/SiteLayout';
import type { UserInviteRequest, UserInviteResponse } from '../types/UserInvite';
import { getAuthorizationToken, getRefreshTokenFromStore } from '../utils/token';
import { getAccessToken, setAccessToken } from './token';
import type { ApiProvider, InterceptorCallbacks, TokenFunctions } from './types';

type QueueItem = (accessToken: string) => void;

interface WorkerGlobalScopeWithLocalStorage extends WorkerGlobalScope {
  localStorage?: any;
}

const setRequestConfigAuthorizationHeader = (config: AxiosRequestConfig, accessToken: string) => {
  config.headers = { ...config.headers, Authorization: getAuthorizationToken(accessToken) };
};

type GetAccessTokenProxy = () => Promise<string>;
type SetAccessTokenProxy = (value: string) => Promise<void>;
type GetAccessToken = typeof getAccessToken | GetAccessTokenProxy;
type SetAccessToken = typeof setAccessToken | SetAccessTokenProxy;

export default class RealApiProvider implements ApiProvider {
  private axiosInst?: AxiosInstance;

  private getAccessToken: GetAccessToken = () => '';

  private setAccessToken: SetAccessToken = () => {
    return;
  };

  async updateAccessToken() {
    // Updates the token and writes it to localStorage.
    const refreshToken = await getRefreshTokenFromStore();
    if (!refreshToken) {
      return Promise.reject();
    }
    const authResponse = await this.refreshTokens(refreshToken);
    const { accessToken } = { ...authResponse };
    if (authResponse) {
      if (accessToken) {
        await this.setAccessToken(accessToken);
      }
      return authResponse;
    }
    return Promise.reject();
  }

  setInterceptors(interceptorCallbacks: InterceptorCallbacks) {
    invariant(this.axiosInst, 'Axios instance required');
    const { onTokenRefreshFailed, onTokenRefreshSuccess } = interceptorCallbacks;
    let queue: QueueItem[] = [];
    let isRefreshing = false;

    const processQueue = (accessToken: string) => {
      queue.forEach((queueItem) => queueItem(accessToken));
      queue = [];
    };

    this.axiosInst.interceptors.request.use(async (config) => {
      // On each request, adds a token from the localStorage to the header
      const accessToken = await this.getAccessToken();
      if (accessToken) {
        // adding actual access token to every request
        setRequestConfigAuthorizationHeader(config, accessToken);
      }
      return config;
    });

    this.axiosInst.interceptors.response.use(
      (response) => response,
      async (error) => {
        // When an error about an expired token (401) is received, it launches a token update.
        // While the token is being updated, it adds other requests with the same error to the queue.
        // All requests are re-sent when a fresh token is received.
        const { response, config } = error;
        const { status } = response;
        if (status === 401 && !config._retry) {
          // Request that has already occurred with an error does not repeat again and does not enter into a loop.
          config._retry = true;
          if (!isRefreshing) {
            try {
              isRefreshing = true;
              const authRefreshResponse = await this.updateAccessToken();
              const { accessToken } = authRefreshResponse;
              setRequestConfigAuthorizationHeader(config, accessToken);
              onTokenRefreshSuccess(authRefreshResponse);
              isRefreshing = false;
              processQueue(accessToken);
              return this.axiosInst?.(config);
            } catch (e) {
              onTokenRefreshFailed(e as Error);
            }
          } else {
            return new Promise((resolve) => {
              queue.push((accessToken: string) => {
                setRequestConfigAuthorizationHeader(config, accessToken);
                resolve(this.axiosInst?.(config));
              });
            });
          }
        }
        return Promise.reject(error);
      },
    );
  }

  async init(
    interceptorCallbacks: InterceptorCallbacks,
    tokenFunctions?: TokenFunctions,
    localStorage?: any,
  ) {
    const { REACT_APP_API_TIMEOUT, REACT_APP_API_URL_PREFIX } = process.env;
    this.getAccessToken = tokenFunctions?.workerGetAccessToken || getAccessToken;
    this.setAccessToken = tokenFunctions?.workerSetAccessToken || setAccessToken;
    if (localStorage) {
      // gives the worker access to the localStorage
      // eslint-disable-next-line no-restricted-globals
      (self as WorkerGlobalScopeWithLocalStorage).localStorage = localStorage;
    }
    this.axiosInst = axios.create({
      baseURL: REACT_APP_API_URL_PREFIX,
      timeout: Number(REACT_APP_API_TIMEOUT),
    });
    this.setInterceptors(interceptorCallbacks);
  }

  async refreshTokens(refreshToken: string) {
    // The "axiosInst" is not used here, so as not to make an exception for it in the interceptor and exclude
    // the entry into the loop when refreshing the token
    const { REACT_APP_API_URL_PREFIX } = process.env;
    const result = await axios.post<AuthRefreshTokenResponse>(
      `${REACT_APP_API_URL_PREFIX}/sessions/v1/pkce/tokens/refresh`,
      {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        refreshToken,
      },
    );
    return result.data;
  }

  async getCompanyFleets(companyId: string): Promise<Fleet[]> {
    invariant(this.axiosInst, 'Axios instance required');
    const fleets = await this.axiosInst.get(`/fleets/v1`, { params: { companyId: companyId } });
    return fleets.data;
  }

  async getUserCompanies(userId: string): Promise<CompanyId[]> {
    invariant(this.axiosInst, 'Axios instance required');
    const companyIds = await this.axiosInst.get(`/company/v2`, { params: { userId } });
    return companyIds.data;
  }

  async getCompany(id: string): Promise<Company> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.get(`/pwrfleet-ui/v1/companies/${id}`);
    return result.data;
  }

  async getCompanies(): Promise<Company[]> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.get('/company/v2/');
    return result.data;
  }

  async getFleetSites({
    fleetId,
    ...params
  }: SitesRequest & SiteQueryParams): Promise<SitesResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const sites = await this.axiosInst.get(`/fleets/v4/${fleetId}/sites/paginated`, { params });
    return sites.data;
  }

  async getFleetMapSites(fleetId: string): Promise<MapSite[]> {
    invariant(this.axiosInst, 'Axios instance required');
    const params = { fleetId };
    const sites = await this.axiosInst.get(`/sites/v1/address-meta-data`, { params });
    return sites.data;
  }

  async getAccountData(userId: string): Promise<Account> {
    invariant(this.axiosInst, 'Axios instance required');
    const response = await this.axiosInst.get(`/user-ms/v2/users/${userId}`);
    return response.data;
  }

  async updateAccountNameData(
    userId: string,
    firstName: string,
    lastName: string,
  ): Promise<unknown> {
    invariant(this.axiosInst, 'Axios instance required');
    const response = await this.axiosInst.patch(`/accounts/v1/users/${userId}/name`, {
      firstName,
      lastName,
    });
    return response.data;
  }

  async createSiteListExport(
    companyId: string,
    params: SiteListExportParams,
  ): Promise<SiteListExportResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const response = await this.axiosInst.get(`/company/v2/${companyId}/sites/download`, {
      params,
    });
    return response.data;
  }

  async updateAccountPasswordData(
    userId: string,
    currentPassword: string,
    newPassword: string,
  ): Promise<unknown> {
    invariant(this.axiosInst, 'Axios instance required');
    const response = await this.axiosInst.patch(`/sessions/v1/users/${userId}/password`, {
      currentPassword,
      newPassword,
    });
    return response.data;
  }

  async updateCompanyData(
    companyId: string,
    body: UpdateCompanyBody,
  ): Promise<UpdateCompanyResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const response = await this.axiosInst.patch(`/company/v2/${companyId}`, body);
    return response.data;
  }

  async getAccountCompanies(userId: string): Promise<Company[]> {
    invariant(this.axiosInst, 'Axios instance required');
    const response = await this.axiosInst.get(`/company/v2/details?userId=${userId}`);
    return response.data;
  }

  async signUp(params: SignUpRequest): Promise<SignUpResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const response = await this.axiosInst.post(`/sessions/v1/installer/signup`, params);
    return response.data;
  }

  async getSiteAlerts(request: SiteAlertsRequest): Promise<SiteAlertsResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const { siteId, ...params } = request;
    const response = await this.axiosInst.get(`/alerts/v1/sites/${siteId}`, {
      params,
      // added this line to format array query parameter to be something like "?status='ACTIVE,RESOLVED'"
      paramsSerializer: (params) => qs.stringify(params, { encode: true, arrayFormat: 'comma' }),
    });
    return response.data;
  }

  async getCompanyUsers(companyId: string): Promise<CompanyUsersResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.get(`/company/v2/${companyId}/users/details`);
    return result.data;
  }

  async mergeCompanies(params: CompaniesMergeRequest): Promise<CompaniesMergeResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.post(`/sites/v2/companies/merge`, params);
    return result.data;
  }

  async inviteUsers(userId: string, params: UserInviteRequest): Promise<UserInviteResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.post(`/user-ms/v2/users/${userId}/invite`, params);
    return result.data;
  }

  async getPendingCompanyUsers(companyId: string): Promise<PendingCompanyUsersResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.get(`/user-ms/v2/users/company/${companyId}/pending`);
    return result.data;
  }

  async updateCompanyUserRole(
    params: UpdateCompanyUserRoleRequest,
  ): Promise<UpdateCompanyUserRoleResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.patch(`/user-ms/v1/users/update-role`, params);
    return result.data;
  }

  async removeCompanyUser({
    userId,
  }: RemoveCompanyUserRequest): Promise<RemoveCompanyUserResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.delete(`/user-ms/v2/users/${userId}`);
    return result.data;
  }

  async getCompanyAssociations(companyId: string): Promise<CompanyAssociationsResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.get(`/company/v1/tpo/${companyId}/associations`);
    return result.data;
  }

  async associateCompanies(params: AssociateCompaniesRequest): Promise<AssociateCompaniesResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.post('/company/v1/tpo/associations', params);
    return result.data;
  }

  async activateCompanyAssociation(
    associationId: string,
  ): Promise<ActivateCompanyAssociationResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.patch(
      `/company/v1/tpo/association/${associationId}/activate`,
    );
    return result.data;
  }

  async removeCompanyAssociation(associationId: string): Promise<RemoveCompanyAssociationResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.delete(`/company/v1/tpo/${associationId}`);
    return result.data;
  }

  async getSiteAssociations(siteId: string): Promise<SiteAssociationsResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.get(`/sites/v2/tpo/site/${siteId}/access`);
    return result.data;
  }

  async getCompanyAlerts(params: CompanyAlertsRequest): Promise<CompanyAlertsResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const { companyId, ...query } = params;
    const response = await this.axiosInst.get(`/alerts/v1/companies/${companyId}`, {
      params: query,
    });
    return response.data;
  }

  async getAlertDetails(params: AlertDetailsRequest): Promise<AlertDetailsResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const { alertId } = params;
    const result = await this.axiosInst.get(`/alerts/v1/alerts/${alertId}/details`);
    return result.data;
  }

  async getSiteAddress(params: SiteAddressRequest): Promise<SiteAddressResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const response = await this.axiosInst.post(`/sites/v1/get-address`, params);
    return response.data;
  }

  async getAlertTypeInfoFromUrl(url: string): Promise<any> {
    const response = await axios.get(url);
    return response.data;
  }

  async getAlertHistory({
    alertId,
    ...queryParams
  }: AlertInstanceHistoryRequest): Promise<AlertInstanceHistoryResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    return (
      await this.axiosInst.get(`/alerts/v1/alerts/${alertId}/history`, { params: queryParams })
    ).data;
  }

  async getDeviceEventLog({
    siteId,
    systemId,
    deviceId,
    ...queryParams
  }: DeviceEventLogRequest): Promise<DeviceEventLogResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    return (
      await this.axiosInst.get(
        `/sites/v1/${siteId}/systems/${systemId}/devices/${deviceId}/device-state-log`,
        {
          params: queryParams,
        },
      )
    ).data;
  }

  async pairLoadManager({
    systemId,
    beaconRcpn,
    inverterRcpn,
  }: LoadManagerPairRequest): Promise<LoadManagerPairResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.post(`/loadcontroller/v1/systems/${systemId}/essConfig`, {
      beaconRcpn,
      inverterRcpn,
    });
    return result.data;
  }

  async getSiteLayout(siteId: string): Promise<SiteLayoutResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.get(`/array-layout/v1/layouts?siteId=${siteId}`);
    return result.data;
  }

  async putSiteLayout(
    siteId: string,
    body: SiteLayoutSaveRequest,
  ): Promise<SiteLayoutSaveResponse> {
    invariant(this.axiosInst, 'Axios instance required');
    const result = await this.axiosInst.put(`/array-layout/v1/layouts?siteId=${siteId}`, body);
    return result.data;
  }

  async getSiteLayoutTelemetry(
    body: SiteLayoutTelemetryRequest,
  ): Promise<SiteLayoutTelemetryResponse> {
    invariant(this.axiosInst, 'Axios instance required');

    const parsedStartTime = DateTime.fromISO(body.startTime, { setZone: true });
    const parsedEndTime = DateTime.fromISO(body.endTime, { setZone: true });

    const selectedDateRange = Interval.fromDateTimes(parsedStartTime, parsedEndTime);
    const arrayOfSplitDateTimes = selectedDateRange.splitBy({ days: 1 });

    const axiosRequestsForEachPriorDay = (axiosInstance: AxiosInstance) =>
      arrayOfSplitDateTimes.map((day) => {
        const dataToPost = {
          ...body,
          startTime: day.start.toISO({ suppressMilliseconds: true }),
          endTime: day.start.endOf('day').toISO({ suppressMilliseconds: true }), // we do this because day.end returns the next midnight
        };

        return axiosInstance.post(
          '/microinverter-system/v1/systems/inverter-telemetry',
          dataToPost,
        );
      });

    return await Promise.all(axiosRequestsForEachPriorDay(this.axiosInst)).then((responses) => {
      const flattenedResponses = responses.flatMap((response) => response.data); // iterate through one response per day

      const uniqueSystemIDs = uniq(flattenedResponses.map((response) => response.systemId)); // each response should have one or more systems

      const output: ArrayLayoutTelemetry = uniqueSystemIDs.map((systemId) => {
        // each system should be its own object
        const responsesForThisSystem: SiteLayoutTelemetrySuccessResponse =
          flattenedResponses.filter((response) => response.systemId === systemId); // only the responses for this system
        let inverterDataByID: { [inverterId: string]: { globalInverterId: string; data: any[] } } =
          {};

        responsesForThisSystem.forEach((response) => {
          response.inverterTelemetry.forEach((inverter) => {
            inverterDataByID[inverter.globalInverterId] = {
              globalInverterId: inverter.globalInverterId,
              data: inverterDataByID[inverter.globalInverterId]?.data
                ? [...inverterDataByID[inverter.globalInverterId].data, ...inverter.data]
                : [...inverter.data],
            };
          });
        });

        return {
          systemId: systemId,
          inverterTelemetry: Object.values(inverterDataByID),
        };
      });

      return output;
    });
  }
}
